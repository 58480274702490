<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="权益名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入权益名称" />
      </a-form-model-item>
      <a-form-model-item label="权益小图标" prop="logo" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.logo"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("logo",info)'
          :customRequest='(info) => fileUploadRequest("logo",info)'
        >
          <img v-if="form.logo" :src="form.logo" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
<!--        <a-input v-model="form.logo" placeholder="请输入权益小图标" />-->
      </a-form-model-item>
      <a-form-model-item label="权益大图" prop="img" >
<!--        <a-input v-model="form.img" placeholder="请输入权益大图" />-->
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.img"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("img",info)'
          :customRequest='(info) => fileUploadRequest("img",info)'
        >
          <img v-if="form.img" :src="form.img" alt="img"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="content" >
        <a-textarea v-model="form.content" :row='6'/>
      </a-form-model-item>
<!--      <a-form-model-item label="是否设置数值，0否1是" prop="isSetNum" >-->
<!--        <a-input v-model="form.isSetNum" placeholder="请输入是否设置数值，0否1是" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getEquity, addEquity, updateEquity } from '@/api/vip/equity'
import Editor from '@/components/Editor'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      // 表单参数
      form: {
        id: null,

        name: null,

        logo: null,

        img: null,

        content: null,

        isSetNum: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        content: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ],
        isSetNum: [
          { required: true, message: '是否设置数值，0否1是不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        logo: null,
        img: null,
        content: null,
        isSetNum: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getEquity({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {

          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateEquity(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addEquity(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
